import { PaletteMode } from "@mui/material";
import React from "react";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { ActionType, createCustomAction, getType } from "typesafe-actions";
import { AppState } from "../../redux/reducer";
import { MODE_THEME } from "../constants";

export interface NotifyProps {
  isOpen: boolean;
  title?: React.ReactNode | string;
  message?: React.ReactNode | string;
  onClose?: () => void;
}
export interface CommonState {
  networkErrorMsg?: string;
  openErrorDialog: boolean;
  loading: boolean;
  mode: PaletteMode;
  listCoin: { id: string; name: string }[];
  loadingCoin: boolean;
  notifyProps: NotifyProps;
}

export const setModeTheme = createCustomAction(
  "common/setModeTheme",
  (val: PaletteMode) => ({ val })
);

export const setCloseNotify = createCustomAction("common/setCloseNotify");
export const setOpenNotify = createCustomAction(
  "common/setOpenNotify",
  (val: Omit<NotifyProps, "isOpen">) => ({
    val,
  })
);

export const setOpenErrorDialog = createCustomAction(
  "common/setOpenErrorDialog",
  (val: boolean) => ({ val })
);

export const setNetworkError = createCustomAction(
  "common/setNetworkError",
  (errorMsg: string, openErrorDialog: boolean) => ({
    errorMsg,
    openErrorDialog,
  })
);

export const setLoading = createCustomAction(
  "common/setLoading",
  (val: boolean) => ({ val })
);

export function initialData(): ThunkAction<
  Promise<void>,
  AppState,
  null,
  AnyAction
> {
  return async (dispatch) => {
    // const json = await dispatch(fetchThunk(API_PATHS.searchCurrency));
    // if (json.code === SUCCESS_CODE) {
    //   dispatch(
    //     setListCoin(
    //       json.data?.coins?.map((one) => ({
    //         id: one.id,
    //         name: `${one.name} (${one.symbol})`,
    //       }))
    //     )
    //   );
    // }
  };
}

const actions = {
  setNetworkError,
  setOpenErrorDialog,
  setLoading,
  setModeTheme,
  setOpenNotify,
  setCloseNotify,
};

type Action = ActionType<typeof actions>;

export default function reducer(
  state: CommonState = {
    loading: false,
    openErrorDialog: false,
    notifyProps: { isOpen: false },
    mode: (localStorage.getItem(MODE_THEME) as PaletteMode) || "light",
    listCoin: [],
    loadingCoin: true,
  },
  action: Action
): CommonState {
  switch (action.type) {
    case getType(setModeTheme):
      return { ...state, mode: action.val };
    case getType(setOpenErrorDialog):
      return { ...state, openErrorDialog: action.val };
    case getType(setNetworkError):
      return {
        ...state,
        networkErrorMsg: action.errorMsg,
        openErrorDialog: action.openErrorDialog,
      };
    case getType(setOpenNotify):
      return { ...state, notifyProps: { isOpen: true, ...action.val } };
    case getType(setCloseNotify):
      return {
        ...state,
        notifyProps: { ...state.notifyProps, isOpen: false },
      };
    case getType(setLoading):
      return { ...state, loading: action.val };
    default:
      return state;
  }
}
