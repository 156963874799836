import { AccountCircle } from "@mui/icons-material";
import {
  Box,
  Button,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Outlet } from "react-router";
import { logoutThunk } from "../../auth/reducer/authenticationReducer";
import { PopoverState } from "../../common/components/elements";
import LoadingIcon from "../../common/components/LoadingIcon";
import { some } from "../../common/constants";
import useGeneralHook from "../../common/hook/useGeneralHook";
import { IRouteObject } from "../../config/utils";
import ChangePasswordDialog from "./ChangePasswordDialog";
import DefaultAside from "./DefaultAside";
import {MESSAGE_SUCCESS} from "./constants";

interface Props {
  listRouter: IRouteObject[];
}

const MainLayout: React.FunctionComponent<Props> = (props: Props) => {
  const { listRouter } = props;
  const [openChangePassword, setChangePassword] = React.useState(false);
  const { dispatch, appState, fetchThunk, enqueueSnackbar, API_PATHS, intl } =
    useGeneralHook();

  const onChangePassword = React.useCallback(
    async (value: some, setLoading: (loading: boolean) => void) => {
      setLoading(true);
      try {
        const json = await dispatch(
          fetchThunk(API_PATHS.users.changePassword(value?.id), "put", value)
        );

        if (json.message === MESSAGE_SUCCESS) {
          setChangePassword(false);
          enqueueSnackbar(
            intl.formatMessage({
              id: "changePasswordSuccess",
            }),
            {
              variant: "success",
            }
          );
        }
      } catch (_) {
        enqueueSnackbar(intl.formatMessage({ id: "changePasswordFail" }), {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [dispatch, fetchThunk, API_PATHS.users, enqueueSnackbar, intl]
  );

  return (
    <Box
      display="flex"
      minHeight="100vh"
      style={{ height: "100%", width: "100%" }}
      bgcolor="secondary.main"
    >
      <DefaultAside listRouter={listRouter} />
      <Box
        flexGrow={1}
        width="70vw"
        flex={1}
        position={"relative"}
        display="flex"
        flexDirection="column"
        height="100vh"
        overflow={"hidden"}
      >
        <Box display="flex" padding={2}>
          <Box id="headerContent" flex={1} />
          <PopoverState>
            {({ anchorEl, handleClick, handleClose, open }) => {
              return (
                <>
                  <Button
                    variant="contained"
                    color="info"
                    size="large"
                    onClick={handleClick}
                    startIcon={<AccountCircle />}
                  >
                    {appState.auth.userInfo?.username}
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    PaperProps={{ style: { minWidth: 200 } }}
                  >
                    <MenuItem
                      onClick={() => {
                        setChangePassword(true);
                      }}
                    >
                      <ListItemText>
                        <Typography variant="body1">
                          <FormattedMessage id="changePassword" />
                        </Typography>
                      </ListItemText>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        dispatch(logoutThunk());
                      }}
                    >
                      <ListItemText>
                        <Typography variant="body1">
                          <FormattedMessage id="logout" />
                        </Typography>
                      </ListItemText>
                    </MenuItem>
                  </Menu>
                </>
              );
            }}
          </PopoverState>
        </Box>
        <React.Suspense fallback={<LoadingIcon />}>
          <Outlet />
        </React.Suspense>
      </Box>
      <ChangePasswordDialog
        open={!!openChangePassword}
        onClose={() => {
          setChangePassword(false);
        }}
        onSubmit={onChangePassword}
        formData={{ id: appState.auth.userInfo?.id }}
      />
    </Box>
  );
};

export default MainLayout;
