export const USER_ROLE_LIST = [
    {
        id: 1,
        value: 'MEDRING_ADMIN',
        label: 'account.medring.superAdmin',
        isBasic: false,
        isStandard: false,
        discription: 'Quản trị viên nhà cung cấp phần mềm MEDRING',
    },
    {
        id: 2,
        value: 'MEDRING_MANAGER',
        label: 'account.medring.manager',
        isBasic: false,
        isStandard: false,
        discription: 'Quản lý nhà cung cấp phần mềm MEDRING',
    },
    {
        id: 3,
        value: 'MEDRING_USER',
        label: 'account.medring.user',
        isBasic: false,
        isStandard: false,
        discription: 'Người dùng nhà cung cấp phần mềm MEDRING',
    }
]