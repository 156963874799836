import { CloseRounded } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import {
  Backdrop,
  CircularProgress,
  IconButton,
  Slide,
  ThemeProvider,
} from "@mui/material";
import enLocale from "date-fns/locale/en-US";
import viLocale from "date-fns/locale/vi";
import "moment/locale/vi";
import { SnackbarProvider } from "notistack";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useRoutes } from "react-router";
import "./App.css";
import LoginPage from "./auth/page/LoginPage";
import ConfirmDialogProvider from "./common/components/ConfirmDialogProvider";
import FetchErrorDialog from "./common/components/FetchErrorDialog";
import LoadingIcon from "./common/components/LoadingIcon";
import NotifyDialog from "./common/components/NotifyDialog";
import ProtectedRoute from "./common/components/ProtectedRoute";
import RedirectRoute from "./common/components/RedirectRoute";
import WarningDialogProvider from "./common/components/WarningDialogProvider";
import { initialData } from "./common/reducer/commonReducer";
import { MAIN_LIST_ROUTES, MAIN_ROUTES } from "./common/router";
import { AdapterDateFns as CustomAdapter} from "@mui/x-date-pickers/AdapterDateFns";
import { MUITheme } from "./config/setupTheme";
import MainLayout from "./layout/main/MainLayout";
import { AppState } from "./redux/reducer";

export const localeMap = {
  'vi-VN': viLocale,
  'en-US': enLocale,
};

function App() {
  const dispatch: any = useDispatch();
  const notistackRef = React.useRef<any>(null);
  const mode = useSelector(
    (state: AppState) => state.common.mode,
    shallowEqual
  );

  const { loading } = useSelector((state: AppState) => state.common);
  const locale = useSelector(
    (state: AppState) => state.intl.locale,
    shallowEqual
  );

  const onClickDismiss = (key: any) => () => {
    if (notistackRef.current) {
      notistackRef.current.closeSnackbar(key);
    }
  };

  React.useEffect(() => {
    dispatch(initialData());
  }, [dispatch]);

  const element = useRoutes([
    {
      path: MAIN_ROUTES.login,
      element: (
        <RedirectRoute>
          <LoginPage />
        </RedirectRoute>
      ),
    },
    {
      path: "*",
      element: (
        <ProtectedRoute>
          <MainLayout listRouter={MAIN_LIST_ROUTES} />
        </ProtectedRoute>
      ),
      children: MAIN_LIST_ROUTES,
    },
  ]);

  return (
    <ThemeProvider theme={MUITheme(mode)}>
      <LocalizationProvider
        dateAdapter={CustomAdapter as any}
        adapterLocale={localeMap[locale] as any}
      >
        <SnackbarProvider
          ref={notistackRef}
          maxSnack={3}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          preventDuplicate
          TransitionComponent={Slide}
          action={(key) => (
            <IconButton onClick={onClickDismiss(key)}>
              <CloseRounded style={{ color: "white" }} />
            </IconButton>
          )}
        >
          <ConfirmDialogProvider>
            <WarningDialogProvider>
              <React.Suspense fallback={<LoadingIcon />}>
                {element}
              </React.Suspense>
              <FetchErrorDialog />
              <Backdrop unmountOnExit style={{ zIndex: 2000 }} open={loading}>
                <div style={{ color: "white" }}>
                  <CircularProgress variant="indeterminate" color="inherit" />
                </div>
              </Backdrop>
              <NotifyDialog />
            </WarningDialogProvider>
          </ConfirmDialogProvider>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
