import { Box, Typography } from "@mui/material";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { IS_PRODUCTION_MODE } from "../../common/API_App";
import DialogCustom from "../../common/components/DialogCustom";
import { some } from "../../common/constants";
import SchemaForm from "../../common/SchemaForm";

interface Props {
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  open: boolean;
  onClose: () => void;
  formData?: some;
}

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData, onClose } = props;

  return (
    <>
      <Box display="flex" gap={1} alignItems="center" marginBottom={2}>
        <Typography variant="body1" color="primary">
          <FormattedMessage id="clinic.name" />
          :&nbsp;{formData?.name}
        </Typography>
      </Box>
      <SchemaForm
        onSubmit={onSubmit}
        initialData={{ ...formData, expireDate: null }}
        onCancel={onClose}
        schema={{
          changeDataBeforeSubmit(values) {
            return { ...values, organizationId: formData?.groupData?.id };
          },
          fields: ({ formProps: { intl } }) => {
            return {
              expireDate: {
                type: "datePicker",
                label: intl.formatMessage({ id: "clinic.dateToEndTrial" }),
                register: { required: true },
                disablePast: true,
                minDate: formData?.dateToEndTrial,
              },
            };
          },
        }}
        formProps={{
          autoComplete: "nope",
          autoCorrect: "off",
        }}
      />
    </>
  );
};

const ExtendTrialDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose } = props;

  return (
    <DialogCustom
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      PaperProps={{
        style: { minWidth: 420 },
      }}
      maxWidth="xl"
      title={IS_PRODUCTION_MODE ? "clinic.extendLicense" : "clinic.extendTrial"}
    >
      <Box padding={2}>
        <SchemaFormContent {...props} />
      </Box>
    </DialogCustom>
  );
};
export default ExtendTrialDialog;
