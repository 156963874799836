import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  Paper,
  Skeleton
} from "@mui/material";
import { Editor, IAllProps } from "@tinymce/tinymce-react";
import React, { forwardRef, memo, useEffect, useRef, useState } from "react";
import useGeneralHook from "../../../hook/useGeneralHook";
import "./styles.scss";

export function extractHTMLContent(html) {
  if (!html) {
    return null;
  }
  return new DOMParser().parseFromString(html, "text/html").documentElement
    .textContent;
}

export interface TextEditorElementProps
  extends Omit<IAllProps, "defaultValue" | "onChange"> {
  readOnly?: boolean;
  label?: React.ReactNode;
  placeholder?: string;
  required?: boolean;
  className?: string;
  value?: string;
  onChange?: (value: string) => void;
  error?: boolean;
  name?: string;
  height?: number | string;
  isSubmitting?: boolean;
  addAmazonProduct?: boolean;
}

interface Props extends TextEditorElementProps {}

const TextEditorElement = forwardRef((props: Props, ref: any) => {
  const {
    name,
    error,
    readOnly,
    required,
    label,
    className,
    disabled,
    value,
    onChange,
    height,
    isSubmitting,
    placeholder,
    onFocusIn,
    onBlur,
    addAmazonProduct: addAmazonProductUrl,
    ...rest
  } = props;
  const { theme } = useGeneralHook();
  const isDarkTheme = theme.palette.mode === "dark";
  const refTmp = useRef<any>(null);
  const editorRef = useRef<any>(null);
  const [isFocus, setFocus] = useState(false);
  const [loading, setLoading] = React.useState<boolean>(true);

  const BASE_TOOLBAR_TEXT_EDITOR = [
    // {
    //   name: "styles",
    //   items: ["formatpainter", "casechange", "blocks"],
    // },
    {
      name: "styles",
      items: ["style-select"],
    },
    {
      name: "headings",
      items: ["blocks"],
    },

    {
      name: "formatting",
      items: ["bold", "italic", "underline"],
    },
    {
      name: "alignment",
      items: ["alignleft", "aligncenter", "alignright", "alignjustify"],
    },
    {
      name: "list",
      items: ["bullist", "numlist"],
    },
    {
      name: "indentation",
      items: ["link", "image", "removeformat"],
    },
    {
      name: "control",
      items: addAmazonProductUrl
        ? ["outdent", "indent", "addAmazonProduct", "fullscreen"]
        : ["outdent", "indent", "fullscreen"],
    },
  ];

  const getEditorInstance = (evt: any, editor: any) => {
    editorRef.current = editor;
  };

  useEffect(() => {
    if (!!error !== isSubmitting) {
      if (!!error) {
        editorRef.current.execCommand("mceFocus");
        refTmp?.current.scrollIntoView();
      }
    }
  }, [error, isSubmitting]);

  return (
    <>
      <FormControl
        error={!!error}
        className={!!error ? `${className} MuiFormControl-error` : className}
        fullWidth
        style={{ fontSize: 14 }}
        ref={refTmp}
      >
        {label && (
          <InputLabel required={required} shrink>
            {label}
          </InputLabel>
        )}
        {loading && (
          <Paper
            variant="outlined"
            style={{
              width: "100%",
              borderRadius: 0,
              height: height || 360,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box padding={1} gap={1} display={"flex"} width="50%">
              {Array(8)
                .fill(0)
                .map((_, idx) => (
                  <Skeleton
                    key={idx}
                    variant="rectangular"
                    style={{
                      height: 24,
                      flex: 1,
                    }}
                  />
                ))}
            </Box>
            <Divider />
            <Box padding={1} gap={1} display={"flex"}>
              {Array(8)
                .fill(0)
                .map((_, idx) => (
                  <Skeleton
                    key={idx}
                    variant="rectangular"
                    style={{
                      height: 24,
                      flex: 1,
                    }}
                  />
                ))}
            </Box>
            <Divider />
            <Box padding={1} flex={1}>
              <Skeleton
                variant="rectangular"
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </Box>
          </Paper>
        )}
        <Box
          display={loading ? "none" : ""}
          key={`${isDarkTheme}`}
          sx={{
            "& .tox-tinymce": {
              border: 1,
              borderColor: "divider",
              borderRadius: 1,
            },
          }}
        >
          <Editor
            apiKey="8391gf9rtzhu8mhsuzmx1uebstrxdhejzjym972rutkjwego"
            onInit={getEditorInstance}
            value={value || ""}
            scriptLoading={{ async: true }}
            onEditorChange={(text, editor) => {
              isFocus && onChange && onChange(text);
            }}
            onFocusIn={(...event) => {
              setFocus(true);
              onFocusIn && onFocusIn(...event);
            }}
            onBlur={(...event) => {
              setFocus(false);
              onBlur && onBlur(...event);
            }}
            {...rest}
            init={{
              height: height || 360,
              menubar: true,
              branding: false,
              content_css: isDarkTheme ? "dark" : undefined,
              skin: isDarkTheme ? "oxide-dark" : undefined,
              init_instance_callback: () => {
                setLoading(false);
              },
              plugins: [
                // "a11ychecker",
                "advlist",

                // "advcode",
                // "advtable",
                "autolink",
                // "checklist",
                // "export",
                "code",
                "lists",
                "link",
                "image",
                "charmap",
                "print",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                // "powerpaste",
                "fullscreen",
                // "formatpainter",
                "insertdatetime",
                "media",
                "table",
                "help",
                "wordcount",
              ],
              rel_list: [
                { title: "Default", value: "nofollow noreferrer noopener" },
              ],
              default_rel_list: "nofollow noreferrer noopener",
              target_list: [
                { title: "New page", value: "_blank" },
                { title: "Same page", value: "_self" },
                { title: "Parent frame", value: "_parent" },
                { title: "None", value: "" },
              ],
              link_class_list: [
                { title: "None", value: "text-blue-900 hover:underline" },
                {
                  title: "View button",
                  value: "btn-primary",
                },
              ],
              default_link_target: "_blank",
              toolbar: BASE_TOOLBAR_TEXT_EDITOR,
              toolbar_groups: {
                headings: {
                  icon: "format",
                  tooltip: "Headings",
                  items: "h1 | h2 | h3 | h4 | h5 | h6",
                },
              },
              content_style:
                "body { font-family:Inter,Helvetica,Arial,sans-serif; font-size:14px } p {margin: 0}",
              placeholder: placeholder,
              automatic_uploads: true,
              // images_upload_url: "postAcceptor.php",
              file_picker_types: "image",
              /* we override default upload handler to simulate successful upload*/
              // images_upload_handler: async (blobInfo, progress) => {
              //   console.log("blobInfo", blobInfo.blob());
              //   let formData = new FormData();
              //   formData.append("file", blobInfo.blob(), blobInfo.filename());
              //   progress(0);
              //   const json = await dispatch(
              //     fetchThunk(
              //       API_PATHS.uploadFile(projectCode),
              //       "post",
              //       blobInfo,
              //       "multipart/form-data"
              //     )
              //   );
              //   progress(100);
              //   return new Promise((resolve, reject) => {
              //     if (json.code === SUCCESS_CODE) {
              //       resolve(json.data?.link);
              //     } else {
              //       reject("Can not upload file !");
              //     }
              //   });
              // },
              ...rest.init,
            }}
            disabled={disabled || readOnly}
          />
        </Box>
      </FormControl>
    </>
  );
});

export default memo(TextEditorElement);
