import { some } from "../common/constants";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function scrollTo(id: string, offsetTop: number) {
  const el = document.getElementById(id);
  if (el) {
    window.scrollTo({ top: el.offsetTop - offsetTop, behavior: "smooth" });
  }
}

export const stringifyUrl = function (obj) {
  var str: string[] = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      const value = obj[p];
      str.push(
        encodeURIComponent(p) +
          "=" +
          encodeURIComponent(
            typeof value === "object"
              ? JSON.stringify(value)
              : typeof value === "string" || typeof value === "number"
              ? value
              : ""
          )
      );
    }
  return str.join("&");
};

export const reorder = (list: some[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function CustomAdapter(options) {
  const adapter = new AdapterDateFns(options);

  const constructDayObject = (day) => ({ charAt: () => day });

  return {
    ...adapter,

    getWeekdays() {
      const customWeekdays = adapter.getWeekdays();

      return customWeekdays.map((day) => constructDayObject(day));
    },
  };
}

export const generalPath = (path = "") => {
  if (path?.startsWith("/")) {
    return path;
  } else {
    return `/${path}`;
  }
};

export interface IRouteObject {
  caseSensitive?: boolean;
  children?: IRouteObject[];
  element?: React.ReactNode;
  icon?: React.ReactNode;
  index?: boolean;
  hidden?: boolean;
  path?: string;
  title?: string;
  listRole?: string[][];
}

export function getMachineId() {

  let machineId = localStorage.getItem('MachineId');

  if (!machineId) {
    machineId = String(window.crypto['randomUUID']());
    localStorage.setItem('MachineId', machineId);
  }

  return machineId;
}
