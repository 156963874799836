import {
  FormControlLabel,
  FormControlLabelProps,
  InputLabel,
  Switch,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import React from "react";

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      left: 12,
    },
    "&:after": {
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },

  "& .MuiSwitch-switchBase": {
    color: theme.palette.grey[500],
    "&.Mui-disabled": {
      color: theme.palette.mode === "dark" ? "#fff" : "#c6c6c6",
      "& + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-checked": {
      color: theme.palette.mode === "dark" ? "#fff" : "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
    },
    "& + .MuiSwitch-track": {
      backgroundColor: "inherit",
      opacity: 1,
      border: 1,
      borderStyle: "solid",
      borderColor: theme.palette.grey[500],
    },
  },
}));

const switchStyle = makeStyles(() => ({
  root: {
    marginTop: 0,
  },
  labelPlacementStart: { marginTop: 0 },
}));

export interface PropsSwitchElement
  extends Omit<
    FormControlLabelProps,
    "label" | "control" | "defaultValue" | "onChange"
  > {
  label?: React.ReactNode;
  onChange?: (value: boolean) => void;
  required?: boolean;
  error?: boolean;
}
export interface Props extends PropsSwitchElement {}

const SwitchElement = React.forwardRef<any, Props>((props: Props, ref) => {
  const {
    labelPlacement,
    label = "",
    onChange,
    required,
    value,
    error,
    ...rest
  } = props;
  const classes = switchStyle();

  return (
    <FormControlLabel
      label={
        <InputLabel
          required={required}
          error={error}
          shrink
          style={{
            padding: 0,
            display: "flex",
            alignItems: "center",
            lineHeight: "unset",
          }}
        >
          {label}
        </InputLabel>
      }
      control={<Android12Switch color="primary" inputRef={ref} />}
      labelPlacement={labelPlacement}
      classes={{
        root: classes.root,
      }}
      {...rest}
      checked={value as any}
      onChange={(e, checked) => {
        onChange && onChange(checked);
      }}
    />
  );
});

export default SwitchElement;
