import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { IconButton, InputAdornment, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FormattedMessage } from "react-intl";
import { IS_PRODUCTION_MODE } from "../../common/API_App";
import SchemaForm from "../../common/SchemaForm";
import { LOGO_MADI } from "../../svg";

export interface ILogin {
  username: string;
  password: string;
}

interface Props {
  onSubmit(data: ILogin, setLoading: (loading: boolean) => void): void;
}

const LoginBox = (props: Props) => {
  const { onSubmit } = props;

  return (
    <>
      <Paper sx={{ width: 460, overflow: "hidden" }} elevation={3}>
        <Box
          sx={{
            padding: "48px 100px 86px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box marginBottom={3}>
            <LOGO_MADI />
            <Typography
              variant="body2"
              textTransform="uppercase"
              color="primary"
            >
              <FormattedMessage
                id={IS_PRODUCTION_MODE ? "production" : "demo"}
              />
            </Typography>
          </Box>
          <SchemaForm
            initialData={{
              username: "",
              password: "",
              organizationCode: "0",
            }}
            onSubmit={onSubmit}
            hideSubmitButton
            schema={{
              fields: ({ formProps: { intl, state, setState } }) => ({
                organizationCode: {
                  type: "hidden",
                },
                username: {
                  type: "text-field",
                  label: intl.formatMessage({ id: "username" }),
                  placeholder: intl.formatMessage({ id: "enter" }),
                  inputProps: {
                    autoComplete: "username",
                    name: "username",
                    "aria-label": "username",
                  },
                  register: {
                    required: true,
                    // pattern: {
                    //   value: EMAIL_REGEX,
                    //   message: intl.formatMessage({ id: "emailInvalid" }),
                    // },
                  },
                },
                password: {
                  type: "text-field",
                  inputProps: {
                    autoComplete: "password",
                    name: "password",
                    "aria-label": "password",
                  },
                  inputType: state?.showPassword ? "text" : "password",
                  label: intl.formatMessage({ id: "password" }),
                  placeholder: intl.formatMessage({ id: "enter" }),
                  register: {
                    validate: {
                      required: (value) =>
                        !value?.trim()
                          ? intl.formatMessage({ id: "required" })
                          : true,
                      // lessThan: (value) =>
                      //   value.length < 8 || value.length > 24
                      //     ? intl.formatMessage({ id: "minLength" })
                      //     : true,
                    },
                  },
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          tabIndex={-1}
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setState({ showPassword: !state.showPassword })
                          }
                        >
                          {state.showPassword ? (
                            <Visibility fontSize="small" />
                          ) : (
                            <VisibilityOff fontSize="small" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                },
                submit: {
                  unregister: true,
                  type: () => (
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      loading={state.loading}
                      fullWidth
                      style={{ marginTop: 8 }}
                    >
                      <FormattedMessage id="login" />
                    </LoadingButton>
                  ),
                },
              }),
              changeDataBeforeSubmit: ({ captcha, ...values }) => {
                return { ...values };
              },
            }}
          />
        </Box>
      </Paper>
    </>
  );
};

export default LoginBox;
