import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { logoutThunk } from "../../auth/reducer/authenticationReducer";
import { AppState } from "../../redux/reducer";
import { some, TOKEN } from "../constants";
import { setNetworkError } from "./commonReducer";

export function fetchThunk(
  url: string,
  method: "delete" | "put" | "get" | "post" | "PATCH" = "get",
  body?: some | FormData | string,
  contentType?: string,
  version?: string,
  fallback = { cancelled: false, data: {} }
): ThunkAction<Promise<some>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    while (true) {
      let res: Response | null = null;
      const token = sessionStorage.getItem(TOKEN) || "";
      try {
        res = await fetch(url, {
          method,
          body:
            body instanceof Blob
              ? body
              : typeof body === "object"
              ? JSON.stringify(body)
              : body,
          headers: version
            ? {
                "Cache-Control": "no-cache",
                Authorization: `Bearer ${token}`,
                "Content-Type": contentType || "application/json",
                "If-Match": `W/"${version}"`,
              }
            : {
                "Cache-Control": "no-cache",
                Authorization: `Bearer ${token}`,
                "Content-Type": contentType || "application/json",
              },
        });
      } catch (_) {
        // console.log(_);
      }

      if (res) {
        if (res.ok) {
          if (method === "delete") {
            return {};
          }
          if (fallback.cancelled) {
            return fallback.data;
          }
          if (contentType === "image/jpeg") {
            return res.blob();
          }

          const newToken = res.headers.get("newToken");
          if (newToken) {
            sessionStorage.setItem(TOKEN, newToken);
          }

          const json = await res.json();
          return json;
        }

        if (res.status === 400 || res.status === 402 || res.status === 403 || res.status === 404) {
          const json = await res.json();
          throw json;
        }
        if (res.status === 409) {
          throw new Error("409");
        }
        if (res.status === 501) {
          throw new Error(res.statusText);
        }
        if (res.status === 401 && url.indexOf('sign-out') < 0) {
          dispatch(logoutThunk());
          throw new Error("Invalid token");
        }
        if (res.status === 418) {
          throw new Error("Teapot");
        }
      }

      let hasInternet = true;
      try {
        await fetch("https://www.google.com", { mode: "no-cors" });
      } catch (_) {
        hasInternet = false;
      }

      dispatch(
        setNetworkError(hasInternet ? "serverProblem" : "unstableNetwork", true)
      );

      do {
        await new Promise((resolve) => setTimeout(resolve, 250));
        if (!getState().common.openErrorDialog) {
          break;
        }
      } while (getState().common.networkErrorMsg);
      if (!getState().common.openErrorDialog) {
        break;
      }
      continue;
    }
    return {};
  };
}
