import { PaletteMode } from "@mui/material";
import { blueGrey, grey, red } from "@mui/material/colors";
import {
  alpha,
  darken,
  unstable_createMuiStrictModeTheme as createTheme,
} from "@mui/material/styles";
import shadows from "@mui/material/styles/shadows";

const LightTheme = () => {
  const PRIMARY = "#003CA6";
  const SECONDARY = "#E0ECFF";
  return createTheme({
    components: {
      MuiTableContainer: {
        defaultProps: {
          sx: { bgcolor: "background.paper" },
        },
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: "4px 8px",
            fontSize: 14,
            fontWeight: 500,
            border: "none",
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&:hover": {
              background: SECONDARY
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: { padding: 4 },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          positionStart: {
            marginLeft: 8,
            marginRight: 0,
          },
          positionEnd: {
            marginLeft: 0,
            marginRight: 12,
          },
        },
      },
      MuiTooltip: {
        defaultProps: { arrow: true },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            justifyContent: "flex-end",
            overflowY: "hidden",
            minHeight: 48,
          },
          selectRoot: {
            margin: "0 16px 0 8px",
            minWidth: "64px",
          },
          selectIcon: {
            top: "calc(50% - 14px)",
          },
          menuItem: { fontSize: 12 },
          input: {
            "& .MuiTablePagination-select": {
              textAlign: "left",
              textAlignLast: "left",
              background: "inherit",
              border: `0.5px solid ${grey[400]}`,
              borderRadius: "2px",
              fontSize: 14,
              padding: "3px 12px",
            },
          },
          actions: {
            marginLeft: "10px",
            "& .MuiIconButton-root": {
              padding: "6px",
            },
          },
          spacer: {
            flex: "unset",
          },
          // even: {
          //   background: "white",
          // },
          // odd: {
          //   background: BLUE_200,
          // },
        },
      },
      MuiTypography: {
        defaultProps: {
          color: "textPrimary",
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: { height: 1 },
        },
      },
      MuiButton: {
        defaultProps: {
          variant: "contained",
          color: "primary",
        },
        styleOverrides: {
          root: {
            borderRadius: 100,

            padding: "0px 16px",
          },
          contained: {},
          text: {},
          sizeLarge: {
            minHeight: 46,
            fontSize: 16,
            fontWeight: 700,
          },
          sizeMedium: {
            minHeight: 32,
            fontSize: 14,
            fontWeight: 500,
          },
          sizeSmall: {
            minHeight: 24,
          },
          containedSecondary: { color: "#FFFFFF" },
          textInherit: {
            color: "#E8E8E8",
          },
          containedInfo: {
            backgroundColor: "white",
            color: PRIMARY,
            borderRadius: 16,
            "&:hover": {
              backgroundColor: grey[50],
            },
          },
        },
      },
      MuiSelect: {
        defaultProps: { style: { height: 32 } },
        styleOverrides: {
          icon: {
            right: 8,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: 16,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: "#181A20",
          },
        },
      },
      MuiFormLabel: {
        defaultProps: { sx: { color: "text.primary" } },
        styleOverrides: {
          root: {
            fontSize: 12,
            paddingBottom: 8,
            lineHeight: 1,
            margin: 0,
          },
        },
      },
      MuiInputLabel: {
        defaultProps: {
          shrink: true,
        },
        styleOverrides: {
          root: {
            paddingBottom: 8,
            fontWeight: 500,
            fontSize: 16,
            color: blueGrey[500],
            "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
              transform: "unset",
              position: "relative",
            },
            "&.MuiInputLabel-shrink": {
              transform: "unset",
              position: "relative",
            },
          },
          asterisk: {
            color: red[600],
            "&::before": {
              color: blueGrey[400],
              content: '" ("',
            },
            "&::after": {
              color: blueGrey[400],
              content: '" )"',
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: { height: 8 },
          contained: { marginLeft: 0 },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: { padding: 0 },
          adornedEnd: { padding: 0 },
          adornedStart: { padding: 0 },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          inputRoot: {
            "& .MuiAutocomplete-endAdornment": {
              position: "relative",
              right: "0px !important",
            },
            paddingRight: "8px !important",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          multiline: {
            padding: "0px !important",
          },
          input: {
            padding: "4px 8px !important",
            minHeight: 24,
          },
          inputMultiline: {
            height: "auto !important",
            padding: "8px 8px !important",
          },
          root: {
            borderRadius: 4,
            fontSize: 14,
            backgroundColor: "#fff",
            "& fieldset": {
              top: 0,
              "& legend": {
                display: "none",
              },
            },
            "& .MuiAutocomplete-popper.MuiAutocomplete-paper": {
              padding: 0,
            },
          },
        },
      },
    },
    typography: {
      htmlFontSize: 14,
      fontSize: 14,
      button: { textTransform: "none" },
      fontFamily: "Roboto",
      h1: { fontSize: 96, lineHeight: "112px", fontWeight: 300 },
      h2: { fontSize: 60, lineHeight: "72px", fontWeight: 300 },
      h3: { fontSize: 48, lineHeight: "56px", fontWeight: 400 },
      h4: { fontSize: 34, lineHeight: "36px", fontWeight: 400 },
      h5: { fontSize: 24, lineHeight: "24px", fontWeight: 400 },
      h6: { fontSize: 20, lineHeight: "24px", fontWeight: 500 },
      subtitle1: {
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 500,
      },
      subtitle2: {
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 500,
      },
      body1: {
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 400,
      },
      body2: {
        fontSize: "14px",
        lineHeight: "24px",
        fontWeight: 400,
      },
      caption: {
        fontSize: "12px",
        lineHeight: "14px",
        fontWeight: 500,
      },
      fontWeightBold: 600,
    },
    shadows: [
      "none",
      "0px 6px 20px rgba(0, 60, 166, 0.25)",
      "0px 10px 30px rgba(45, 108, 183, 0.25)",
      "0px 46px 50px -40px rgba(10, 52, 133, 0.4)",
      "0px 16px 22px rgba(0, 0, 0, 0.08)",
      "0px 28px 32px rgba(0, 0, 0, 0.08)",
      "0px 32px 64px rgba(0, 0, 0, 0.08)",
      ...shadows.slice(7),
    ] as any,
    palette: {
      primary: {
        light: alpha(PRIMARY, 0.9),
        main: PRIMARY,
        dark: darken(PRIMARY, 0.1),
        contrastText: "#ffffff",
      },
      secondary: {
        light: alpha(SECONDARY, 0.9),
        main: SECONDARY,
        dark: darken(SECONDARY, 0.1),
        contrastText: "#ffffff",
      },
      text: {
        primary: grey[900],
        secondary: blueGrey[500],
      },
      background: {
        paper: "#fff",
        default: "#F9F9F9",
      },
      grey: { 300: "#E3E3E3" },
    },
  });
};

export const MUITheme = (mode?: PaletteMode) => {
  return LightTheme();
};
