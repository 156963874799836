import { batch } from "react-redux";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { ActionType, createCustomAction, getType } from "typesafe-actions";
import {some, SUCCESS_MESSAGE} from "../../common/constants";
import { AppState, clearStoreAfterLogout } from "../../redux/reducer";
import { ROLES } from "../constants";
import {fetchThunk} from "../../common/reducer/thunk";
import {API_PATHS} from "../../common/API_App";

export interface AuthenticationState {
  authentication: boolean;
  userInfo?: some;
}

export const authenticationIn = createCustomAction("authentication/in");
export const authenticationOut = createCustomAction("authentication/out");

export const saveUserInfo = createCustomAction(
  "authentication/saveUserInfo",
  (userInfo: some) => ({ userInfo: userInfo })
);

export function logoutThunk(): ThunkAction<void, AppState, null, AnyAction> {
  return async (dispatch) => {
    const json = await dispatch(fetchThunk(API_PATHS.auth.logout, "post"));
    batch(() => {
      dispatch(authenticationOut());
      dispatch(clearStoreAfterLogout());
    });
  };
}

const actions = {
  authenticationIn,
  authenticationOut,
  saveUserInfo,
};

type ActionT = ActionType<typeof actions>;

export default function authenticationReducer(
  state = { authentication: false, userInfo: { role: ROLES.admin } },
  action: ActionT
): AuthenticationState {
  switch (action.type) {
    case getType(authenticationIn):
      return { ...state, authentication: true };
    case getType(authenticationOut):
      return { ...state, authentication: false };
    case getType(saveUserInfo): {
      return {
        ...state,
        userInfo: action.userInfo,
      };
    }
    default:
      return state;
  }
}
