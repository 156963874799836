import { Box, Typography } from "@mui/material";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { IS_PRODUCTION_MODE } from "../../common/API_App";
import DialogCustom from "../../common/components/DialogCustom";
import { some } from "../../common/constants";
import SchemaForm from "../../common/SchemaForm";
import { EMAIL_REGEX } from "../../config/regex";
import { CLINIC_TYPE_OPTIONS, CLINIC_SPECIALIZATION_OPTIONS } from "./constants"

interface Props {
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  open: boolean;
  onClose: () => void;
  formData?: some;
}

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData, onClose } = props;

  return (
    <>
      <Box display="flex" gap={1} alignItems="center" marginBottom={2}>
        <Typography
          variant="body1"
          color="primary"
          textTransform="uppercase"
          width={120}
        >
          <FormattedMessage id="id" />
          :&nbsp;{formData?.groupData?.code}
        </Typography>
        <Typography variant="body1" color="primary" flex={1}>
          <FormattedMessage id="name" />
          :&nbsp;{formData?.groupData?.name}
        </Typography>
      </Box>
      <SchemaForm
        onSubmit={onSubmit}
        initialData={formData?.clinicData}
        onCancel={onClose}
        schema={{
          changeDataBeforeSubmit(values) {
            return { ...values, organizationId: formData?.groupData?.id };
          },
          fields: ({ formProps: { intl }, valuesField }) => {
            return {
              name: {
                type: "text-field",
                label: intl.formatMessage({ id: "clinic.name" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                register: { required: true },
                propsWrapper: { xs: 4 },
                readOnly: !!valuesField?.id,
              },
              version: {
                type: "select",
                label: intl.formatMessage({ id: "clinic.clinicType" }),
                placeholder: intl.formatMessage({ id: "select" }),
                register: { required: true },
                propsWrapper: { xs: 4 },
                options: CLINIC_TYPE_OPTIONS,
                readOnly: !!valuesField?.id,
              },
              specializationType: {
                type: "select",
                label: intl.formatMessage({ id: "clinic.specialization" }),
                placeholder: intl.formatMessage({ id: "select" }),
                register: { required: true },
                propsWrapper: { xs: 4 },
                options: CLINIC_SPECIALIZATION_OPTIONS
              },
              adminUserName: {
                type: "text-field",
                label: intl.formatMessage({ id: "clinic.username" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                register: { required: true },
                propsWrapper: { xs: 4 },
                hidden: !!valuesField?.id,
              },
              emailContact: {
                type: "text-field",
                label: intl.formatMessage({ id: "email" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                register: {
                  required: true,
                  pattern: {
                    value: EMAIL_REGEX,
                    message: intl.formatMessage({ id: "enter" }),
                  },
                },
                propsWrapper: { xs: 4 },
              },
              phoneContact: {
                type: "text-field",
                inputType: "tel",
                label: intl.formatMessage({ id: "phone" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                register: {
                  required: true,
                },
                propsWrapper: { xs: 4 },
              },
              expireDate: {
                type: "datePicker",
                label: intl.formatMessage({
                  id: IS_PRODUCTION_MODE
                    ? "clinic.endDateOfLicense"
                    : "clinic.dateToEndTrial",
                }),
                register: { required: true },
                propsWrapper: { xs: 4 },
                disablePast: true,
                readOnly: !!valuesField?.id,
              }
            };
          },
        }}
        formProps={{
          autoComplete: "nope",
          autoCorrect: "off",
        }}
      />
    </>
  );
};

const ClinicDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;

  return (
    <DialogCustom
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      PaperProps={{
        style: { width: 1160 },
      }}
      maxWidth="xl"
      title={formData?.clinicData?.id ? "clinic.update" : "clinic.add"}
    >
      <Box padding={2}>
        <SchemaFormContent {...props} />
      </Box>
    </DialogCustom>
  );
};
export default ClinicDialog;
