import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { PAGE_SIZE, some } from "../constants";
import { stringifyUrl } from "../../config/utils";

const defaultPagination = {
  page: 0,
  pageSize: Number(localStorage.getItem(PAGE_SIZE)) || 10,
};
export interface HookPaginationProps
  extends ReturnType<typeof usePaginationHook> {}
interface Pagination {
  page: number;
  pageSize: number;
}
interface Props {
  defaultFilter?: some;
  disableLink?: boolean;
}
const usePaginationHook = (props?: Props) => {
  const { defaultFilter = {}, disableLink } = props || {};
  const location = useLocation();
  const paramsUrl = useMemo(() => {
    let tmp: some = {};
    const search = new URLSearchParams(location.search);
    search.forEach(function (value, key) {
      if (value.trim() !== "") {
        try {
          tmp[key] = JSON.parse(value);
        } catch (e) {
          tmp[key] = value;
        }
      } else {
        tmp[key] = undefined;
      }
    });
    return tmp;
  }, [location.search]);

  const [pagination, setPagination] = useState<Pagination>({
    page: paramsUrl.page || defaultPagination.page,
    pageSize: paramsUrl.pageSize || defaultPagination.pageSize,
  });
  const [filter, setFilter] = useState<some>({
    ...defaultFilter,
    ...paramsUrl,
  });

  const history = useNavigate();
  const clearParams = useCallback(
    (value?: some) => {
      if (disableLink) {
        setPagination(defaultPagination);
        setFilter(value || defaultFilter);
      } else {
        history(
          {
            search: stringifyUrl({
              page: pagination.page,
              size: pagination.pageSize,
              ...(filter?.tab ? { tab: filter.tab } : {}),
              ...(value || defaultFilter),
            }),
          },
          { replace: true, state: location.state }
        );
        setPagination(defaultPagination);
        setFilter({
          ...(filter?.tab ? { tab: filter.tab } : {}),
          ...(value || defaultFilter),
        });
      }
    },
    [
      defaultFilter,
      disableLink,
      filter.tab,
      history,
      location.state,
      pagination.page,
      pagination.pageSize,
    ]
  );

  const setParams = useCallback(
    (form: some) => {
      if (disableLink) {
        setPagination({
          page: form.page || pagination.page,
          pageSize: form.size || pagination.pageSize,
        });
        setFilter({ ...filter, ...form });
      } else {
        history(
          {
            search: stringifyUrl({
              ...pagination,
              ...filter,
              page: 0,
              ...form,
            }),
          },
          { replace: true, state: location.state }
        );
      }
    },
    [disableLink, filter, history, location, pagination]
  );

  const onPageChange = useCallback(
    (event: unknown, newPage: number) => {
      if (disableLink) {
        setPagination((old) => ({ ...old, page: newPage - 1 }));
      } else {
        history(
          {
            search: stringifyUrl({
              ...pagination,
              ...filter,
              page: newPage - 1,
            }),
          },
          { replace: true, state: location.state }
        );
      }
    },
    [disableLink, filter, history, location, pagination]
  );

  const onRowsPerPageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (disableLink) {
        setPagination((old) => ({
          ...old,
          size: parseInt(event.target.value, 10),
          page: 0,
        }));
      } else {
        history(
          {
            search: stringifyUrl({
              ...pagination,
              ...filter,
              size: parseInt(event.target.value, 10),
              page: 0,
            }),
          },
          { replace: true, state: location.state }
        );
      }
    },
    [disableLink, filter, history, location, pagination]
  );

  useEffect(() => {
    localStorage.setItem(PAGE_SIZE, String(pagination.pageSize));
  }, [pagination.pageSize]);

  useEffect(() => {
    if (!disableLink) {
      const { page, size, ...rest } = paramsUrl;
      setPagination({
        page: page || 0,
        pageSize: size || Number(localStorage.getItem(PAGE_SIZE)) || 10,
      });
      setFilter((old) => ({ ...old, ...rest }));
    }
  }, [disableLink, paramsUrl]);

  const { tab, ...restFilter } = filter;

  return {
    pagination,
    filter,
    params: { ...restFilter, ...pagination } as some,
    tab,
    setParams,
    clearParams,
    onPageChange,
    onRowsPerPageChange,
  };
};

export default usePaginationHook;
