import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, IconButton, InputAdornment } from "@mui/material";
import * as React from "react";
import DialogCustom from "../../common/components/DialogCustom";
import { some } from "../../common/constants";
import SchemaForm from "../../common/SchemaForm";

interface Props {
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  open: boolean;
  onClose: () => void;
  formData?: some;
}

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData, onClose } = props;

  return (
    <>
      <SchemaForm
        onSubmit={onSubmit}
        initialData={formData}
        onCancel={onClose}
        schema={{
          fields: ({ formProps: { intl, state, setState } }) => {
            return {
              password: {
                type: "text-field",
                inputType: state?.showNewPassword ? "text" : "password",
                label: intl.formatMessage({ id: "newPassword" }),
                placeholder: intl.formatMessage({ id: "newPasswordEnter" }),
                register: {
                  validate: {
                    required: (value) =>
                      !value ? intl.formatMessage({ id: "required" }) : true,
                    difference: (value) =>
                      state?.oldPassword === value && state?.newPassword
                        ? intl.formatMessage({ id: "matchingOldPassword" })
                        : true,
                  },
                },
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex={-1}
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setState({ showNewPassword: !state?.showNewPassword })
                        }
                      >
                        {state?.showNewPassword ? (
                          <Visibility fontSize="small" />
                        ) : (
                          <VisibilityOff fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              },
              confirmPassword: {
                type: "text-field",
                inputType: state?.showRePassword ? "text" : "password",
                label: intl.formatMessage({ id: "rePassword" }),
                placeholder: intl.formatMessage({ id: "rePassword.enter" }),
                register: {
                  validate: {
                    required: (value) =>
                      !value ? intl.formatMessage({ id: "required" }) : true,
                    positive: (value) =>
                      state?.newPassword !== value && state?.newPassword
                        ? intl.formatMessage({ id: "notMatchingPassword" })
                        : true,
                  },
                },
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex={-1}
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setState({ showRePassword: !state?.showRePassword })
                        }
                      >
                        {state?.showRePassword ? (
                          <Visibility fontSize="small" />
                        ) : (
                          <VisibilityOff fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              },
            };
          },
        }}
        formProps={{
          autoComplete: "nope",
          autoCorrect: "off",
        }}
      />
    </>
  );
};

const ChangePasswordDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose } = props;

  return (
    <DialogCustom
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      PaperProps={{
        style: { width: 420 },
      }}
      title={"changePassword"}
    >
      <Box padding={2}>
        <SchemaFormContent {...props} />
      </Box>
    </DialogCustom>
  );
};
export default ChangePasswordDialog;
