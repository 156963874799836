export type some = { [key: string]: any };

export const TOKEN = "token";
export const MODE_THEME = "mode_theme";
export const PAGE_SIZE = "page_size";
export const SUCCESS_CODE = 200;

export const SUCCESS_MESSAGE = "Success";

export const DATE_TIME_FORMAT = "HH:mm DD/MM/YYYY";
export const DATE_FORMAT = "DD/MM/YYYY";
export const TIME_FORMAT = "HH:mm";
export const BE_DATE_FORMAT = "YYYY-MM-DD";
