import { Navigate } from "react-router";
import { IRouteObject } from "../config/utils";
import CustomersPage from "../demo/customers/CustomersPage";
import InternalUserPage from "../demo/internal-user/InternalUserPage";
import { IconInternalUser, IconPerson } from "../svg";

export const MAIN_ROUTES = {
  login: "login",
  customers: "customers",
  internalUser: "internal-user",
};

export const MAIN_LIST_ROUTES: IRouteObject[] = [
  {
    path: MAIN_ROUTES.customers,
    title: "customers",
    icon: <IconPerson className="svgStroke" />,
    element: <CustomersPage />,
  },
  {
    path: MAIN_ROUTES.internalUser,
    title: "internalUser",
    icon: <IconInternalUser className="svgStroke" />,
    element: <InternalUserPage />,
  },
  {
    path: "*",
    element: <Navigate to={MAIN_ROUTES.customers} />,
    hidden: true,
  },
];
