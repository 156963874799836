import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, ButtonBase, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useLocation, useResolvedPath } from "react-router";
import { IS_PRODUCTION_MODE } from "../../common/API_App";
import useGeneralHook from "../../common/hook/useGeneralHook";
import { IRouteObject } from "../../config/utils";
import { LOGO_MADI } from "../../svg";
import { ASIDE_MIN_WIDTH, ASIDE_WIDTH, HEADER_HEIGHT } from "./constants";
import DefaultAsideItems from "./DefaultAsideItems";
interface Props {
  listRouter: IRouteObject[];
}

const DefaultAside: React.FunctionComponent<Props> = (props) => {
  const { listRouter } = props;
  const { isDarkTheme, theme } = useGeneralHook();
  const { pathname } = useLocation();
  const [hoverOpen, setHover] = React.useState(false);
  const [openAside, setOpenAside] = React.useState(true);
  const openMerge = openAside || hoverOpen;
  const resolvedLocation = useResolvedPath("");
  const mappedRoutes = (list: IRouteObject[], parent?: IRouteObject) => {
    return list
      .map((one) => {
        if (one.hidden) {
          return undefined;
        }
        return {
          ...one,
          path: one.index
            ? parent?.path
            : parent?.path !== "/"
            ? `${parent?.path}/${one.path}`
            : one.path,
          children: one.children && mappedRoutes(one.children, one),
        };
      })
      .filter(Boolean);
  };

  const listRouterMapped = mappedRoutes(listRouter, {
    path: resolvedLocation.pathname,
  });

  return (
    <>
      <Box
        id="Resizable"
        boxShadow={1}
        style={{
          width: openMerge ? ASIDE_WIDTH : ASIDE_MIN_WIDTH,
          height: "100vh",
          transition: "width 0.3s",
          flexShrink: 0,
          zIndex: 20,
          borderRadius: 0,
          display: "flex",
          flexDirection: "column",
          position: "sticky",
          top: 0,
          willChange: "width",
          background: isDarkTheme
            ? `linear-gradient(rgb(25 35 61), ${theme.palette.background.paper})`
            : theme.palette.background.paper,
        }}
      >
        <Box
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => setHover(false)}
          overflow="hidden"
          height="100%"
          borderRight={isDarkTheme ? 1 : 0}
          borderColor="divider"
          display="flex"
          flexDirection={"column"}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: HEADER_HEIGHT,
            }}
          >
            {openMerge && (
              <div>
                <LOGO_MADI />
                <Typography
                  variant="body2"
                  color="primary"
                  textTransform="uppercase"
                >
                  <FormattedMessage
                    id={IS_PRODUCTION_MODE ? "production" : "demo"}
                  />
                </Typography>
              </div>
            )}
          </Box>
          <PerfectScrollbar style={{ height: "100%" }}>
            <Box
              style={{
                paddingBottom: 16,
                overflowY: "auto",
                overflowX: "hidden",
                flex: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {listRouterMapped?.map((v: IRouteObject, index: number) => (
                <DefaultAsideItems
                  key={index}
                  data={v}
                  pathname={pathname}
                  open={openMerge}
                />
              ))}
            </Box>
          </PerfectScrollbar>
        </Box>
        <Box
          onMouseEnter={() => {
            openAside && setHover(true);
          }}
          onMouseLeave={() => setHover(false)}
        >
          <Tooltip title={<FormattedMessage id="collapse" />} arrow>
            <ButtonBase
              sx={{
                borderRadius: "50%",
                position: "absolute",
                top: 24,
                right: -14,
                minWidth: 28,
                width: 28,
                height: 28,
                bgcolor: "common.white",
                boxShadow: 4,
                border: 1,
                borderColor: "grey.300",
                zIndex: 20,
                transition: "all 0.3s",
                opacity: hoverOpen || !openAside ? 1 : 0,
                "&:hover": {
                  bgcolor: "primary.main",
                  color: "common.white",
                },
              }}
              onClick={() => {
                setOpenAside((old) => !old);
                setHover(false);
              }}
            >
              <ArrowForwardIosIcon
                color="inherit"
                style={{
                  fontSize: 14,
                  transform: openAside ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
            </ButtonBase>
          </Tooltip>
        </Box>
      </Box>
    </>
  );
};

export default DefaultAside;
