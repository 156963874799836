import {
  Box,
  Dialog,
  DialogProps,
  IconButton,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import * as React from "react";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";

interface Props extends Omit<DialogProps, "onClose" | "title"> {
  onClose?: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
  title?: React.ReactNode;
  footer?: React.ReactNode;
}

const DialogCustom: React.FunctionComponent<Props> = (props) => {
  const { children, onClose, title, footer, ...rest } = props;
  const [open, setOpen] = React.useState(rest.open);

  useEffect(() => {
    setOpen(rest.open);
  }, [rest.open]);

  return (
    <Dialog
      keepMounted={false}
      PaperProps={{
        style: { minWidth: 420 },
      }}
      {...rest}
      onClose={() => {
        setOpen(false);
      }}
      TransitionProps={{
        ...rest.TransitionProps,
        onExited: () => {
          onClose && onClose();
        },
      }}
      open={open}
    >
      {title ? (
        <Box
          bgcolor="primary.50"
          style={{
            position: "sticky",
            top: 0,
            padding: "12px 16px",
            zIndex: 2,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box flex={1} textAlign='center'>
            {typeof title === "string" ? (
              <Typography variant="h6" color="primary">
                <FormattedMessage id={title} />
              </Typography>
            ) : (
              title
            )}
          </Box>
          <Box justifySelf="flex-end">
            <IconButton onClick={() => setOpen(false)} size="small">
              <Close fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Box
          position="absolute"
          top={6}
          right={0}
          marginX={2}
          marginY={1}
          zIndex={3}
        >
          <IconButton onClick={() => setOpen(false)} size="small">
            <Close fontSize="small" />
          </IconButton>
        </Box>
      )}
      <Box overflow="auto">{children}</Box>
      {footer && (
        <Box
          bgcolor="primary.50"
          style={{
            position: "sticky",
            bottom: 0,
            padding: "12px 16px",
            zIndex: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {footer}
        </Box>
      )}
    </Dialog>
  );
};
export default DialogCustom;
