import { Link, LinkProps as LinkPropsMui } from "@mui/material";
import { Link as LinkDom, LinkProps } from "react-router-dom";

interface Props extends LinkPropsMui {
  to?: LinkProps["to"];
}

const MyLink = (props: Props) => {
  const { to, children, ...rest } = props;

  if (!!to) {
    return (
      <Link
        variant="inherit"
        underline="hover"
        {...rest}
        to={to}
        component={LinkDom}
      >
        {children}
      </Link>
    );
  }
  return (
    <Link variant="inherit" underline="hover" {...rest}>
      {children}
    </Link>
  );
};
export default MyLink;
