export const CLINIC_TYPE_OPTIONS = [
    {
      id: "STANDARD",
      name: "version.standard",
    },
    {
      id: "BASIC",
      name: "version.basic",
    },
  ];

export const CLINIC_SPECIALIZATION_OPTIONS = [
    {
      id: "ENT",
      name: "specialization.ent",
    },
    {
      id: "PEDIATRICS",
      name: "specialization.pediatrics",
    },
    {
      id: "INTERNAL_MEDICINE",
      name: "specialization.internal_medicine",
    },
    {
      id: "NUTRITION",
      name: "specialization.nutrition",
    },
    {
      id: "DERMATOLOGY",
      name: "specialization.dermatology",
    },
    {
      id: "REHABILITATION",
      name: "specialization.rehabilitation",
    },
    {
      id: "MENTAL_HEALTH",
      name: "specialization.mental_health",
    },
    {
      id: "ORTHOPEDIC",
      name: "specialization.orthopedic",
    },
    {
      id: "CARDIOLOGY",
      name: "specialization.cardiology",
    },
    {
      id: "DENTAL",
      name: "specialization.dental",
    },
    {
      id: "OPHTHALMOLOGY",
      name: "specialization.ophthalmology",
    }
  ];
