import { Box, Typography } from "@mui/material";
import * as React from "react";
import Chart from "react-apexcharts";
import { FormattedMessage } from "react-intl";
import useSWR from "swr";
import DialogCustom from "../../common/components/DialogCustom";
import { some } from "../../common/constants";
import useGeneralHook from "../../common/hook/useGeneralHook";

interface Props {
  open: boolean;
  onClose: () => void;
  formData?: some;
}

const ActiveTimeDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;
  const { dispatch, API_PATHS, fetchThunk } = useGeneralHook();

  const { data = [] } = useSWR(
    formData?.clinicData?.localtionId
      ? API_PATHS.active.index(formData?.clinicData?.localtionId)
      : null,
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.value;
    }
  );

  return (
    <DialogCustom
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      PaperProps={{
        style: { minWidth: 780 },
      }}
      maxWidth="xl"
      title={"clinic.totalActiveTime"}
    >
      <Box padding={2}>
        <Box display="flex" gap={1} alignItems="center" marginBottom={1}>
          <Typography
            variant="body1"
            color="primary"
            textTransform="uppercase"
            flex={1}
          >
            <FormattedMessage id="id" />
            :&nbsp;{formData?.groupData?.partitionId}
          </Typography>
          <Typography variant="body1" color="primary" flex={3}>
            <FormattedMessage id="name" />
            :&nbsp;{formData?.groupData?.partitionName}
          </Typography>
        </Box>
        <Box display="flex" gap={1} marginBottom={2}>
          <Box flex={1}>
            <Typography variant="body1" color="textSecondary">
              <FormattedMessage id="clinic.name" />
            </Typography>
            <Typography variant="body1" marginTop={1}>
              {formData?.clinicData?.clinicName}
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography variant="body1" color="textSecondary">
              <FormattedMessage id="clinic.clinicType" />
            </Typography>
            <Typography variant="body1" marginTop={1}>
              {formData?.clinicData?.clinicType}
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography variant="body1" color="textSecondary">
              <FormattedMessage id="clinic.totalActiveTime" />
            </Typography>
            <Typography variant="body1" marginTop={1}>
              {formData?.clinicData?.totalActiveTime || ""}
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography variant="body1" color="textSecondary">
              <FormattedMessage id="clinic.totalActiveDay" />
            </Typography>
            <Typography variant="body1" marginTop={1}>
              {formData?.clinicData?.totalActiveDay}
            </Typography>
          </Box>
        </Box>
        <Chart
          options={{
            chart: {
              height: 350,
              type: "area",
              toolbar: {
                tools: {
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  download: false,
                  pan: true,
                },
              },
            },
            // forecastDataPoints: {
            //   count: 7,
            // },
            stroke: {
              width: 4,
              // curve: "smooth",
              curve: 'straight'
            },
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              type: "datetime",
              categories: data.map((v) => v.activedate),
              tickAmount: 10,
              // labels: {
              //   formatter: function (value, timestamp, opts) {
              //     return opts.dateFormatter(new Date(timestamp), "dd MMM");
              //   },
              // },
            },
            title: {
              // text: "Forecast",
              align: "left",
              style: {
                fontSize: "16px",
                color: "#666",
              },
            },
            fill: {
              type: "gradient",
              gradient: {
                shade: "dark",
                gradientToColors: ["#003CA6"],
                shadeIntensity: 1,
                type: "vertical",
                opacityFrom: 1,
                opacityTo: 0.0,
                stops: [0, 100, 100, 100],
              },
            },
            yaxis: {
              // max: 40,
            },
          }}
          series={[
            {
              name: "Sales",
              data: data.map((v) => v.activetime),
            },
          ]}
          type="area"
          height={"100%"}
        />
      </Box>
    </DialogCustom>
  );
};
export default ActiveTimeDialog;
