import { Edit } from "@mui/icons-material";
import PasswordRoundedIcon from "@mui/icons-material/PasswordRounded";
import PersonAddAlt1RoundedIcon from "@mui/icons-material/PersonAddAlt1Rounded";
import {
  Box,
  Button,
  IconButton,
  Pagination,
  Paper,
  Portal,
  Tooltip,
} from "@mui/material";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import useSWR from "swr";
import TableCustom from "../../common/components/TableCustom";
import { some } from "../../common/constants";
import useGeneralHook from "../../common/hook/useGeneralHook";
import usePaginationHook from "../../common/hook/usePaginationHook";
import ChangePasswordDialog from "./ChangePasswordDialog";
import UserDialog from "./UserDialog";
import {ROLES_NAME} from "../../auth/constants";
import {MESSAGE_SUCCESS} from "../../layout/main/constants";
import {USER_ROLE_LIST} from "./constant";
interface Props {}

const InternalUserPage: React.FunctionComponent<Props> = (props: Props) => {
  const { dispatch, appState, fetchThunk, enqueueSnackbar, API_PATHS, intl } =
    useGeneralHook();
  const { onPageChange, params } = usePaginationHook();
  const [formData, setFormData] = React.useState<some | undefined>(undefined);
  const [formDataChangePassword, setFormDataChangePassword] = React.useState<
    some | undefined
  >(undefined);
    const [roleData, setRoleData] = React.useState<some | undefined>({id: ' ', name: ' '});

  const { data, isValidating, mutate } = useSWR(
    API_PATHS.users.list(params),
    async (url) => {
      const json = await dispatch(fetchThunk(url));

      const roles = await dispatch(fetchThunk(API_PATHS.roles.list()));
      var roleMedring: any[] = [];
      for (var i = 0; i < roles.length; i++) {
        if (roles[i].name.includes('MEDRING')) {
          const label = USER_ROLE_LIST.find((r) => r.value === roles[i].name)?.label;
          roleMedring.push({id: roles[i].id, name: label});
        }
      }
      setRoleData(roleMedring);
      return json;
    }
  );

  const onUpdateUser = React.useCallback(
    async (value: some, setLoading: (loading: boolean) => void) => {
      setLoading(true);
      try {
        const json = value?.id
          ? await dispatch(
              fetchThunk(API_PATHS.users.update(value?.id), "put", value)
            )
          : await dispatch(fetchThunk(API_PATHS.users.index, "post", value));
        if (json) {
          setFormData(undefined);
          mutate();
          enqueueSnackbar(
            intl.formatMessage({
              id: value?.id ? "updateSuccess" : "createSuccess",
            }),
            {
              variant: "success",
            }
          );
        }
      } catch (_) {
        enqueueSnackbar(
          intl.formatMessage({ id: value?.id ? "updateFail" : "createFail" }),
          {
            variant: "error",
          }
        );
      } finally {
        setLoading(false);
      }
    },
    [dispatch, fetchThunk, API_PATHS.users, mutate, enqueueSnackbar, intl]
  );
  const onChangePassword = React.useCallback(
    async (value: some, setLoading: (loading: boolean) => void) => {
      setLoading(true);
      try {
        const json = await dispatch(
          fetchThunk(API_PATHS.users.changePassword(value?.id), "put", value)
        );

        if (json.message === MESSAGE_SUCCESS) {
          setFormDataChangePassword(undefined);
          enqueueSnackbar(
            intl.formatMessage({
              id: "changePasswordSuccess",
            }),
            {
              variant: "success",
            }
          );
        }
      } catch (_) {
        enqueueSnackbar(intl.formatMessage({ id: "changePasswordFail" }), {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [dispatch, fetchThunk, API_PATHS.users, enqueueSnackbar, intl]
  );

  return (
    <Box flex={1} display="flex" flexDirection="column" height={"100%"}>
      <Portal container={document.getElementById("headerContent")}>
        <Button
          variant="contained"
          color="info"
          disabled={appState.auth.userInfo?.role.some(element => {
              return element !== ROLES_NAME.admin
            }
          )}
          startIcon={<PersonAddAlt1RoundedIcon />}
          size="large"

          onClick={() => {
            setFormData({});
          }}
        >
          <FormattedMessage id="user.add" />
        </Button>
      </Portal>
      <Box padding={2} height="100%">
        <Paper
          sx={{
            padding: 2,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TableCustom
            boxProps={{ style: { flex: 1 } }}
            containerProps={{ style: { minHeight: "unset" } }}
            hideColumnIndex
            dataSource={data?.content?.map((v) => ({ ...v, role: v.roleId }))}
            loading={isValidating}
            columns={[
              { title: "user.name", dataIndex: "username", width: 200 },
              { title: "user.role", dataIndex: "roleName",
                render: (record) => (
                  <FormattedMessage
                    id={record?.roleName ? USER_ROLE_LIST.find((r) => r.value === record.roleName)?.label : ''}
                  />
                ),
              },
              { title: "email", dataIndex: "email" },
              {
                title: "",
                align: "right",
                render: (record) => {
                  return (
                    <Box display="flex" gap={1} justifyContent="flex-end">
                      <Tooltip title={<FormattedMessage id="changePassword" />}>
                        <IconButton
                          size="small"
                          color="primary"
                          disabled={appState.auth.userInfo?.role.some(element => {
                                  return element !== ROLES_NAME.admin
                              }
                          )}
                          onClick={() => setFormDataChangePassword(record)}
                        >
                          <PasswordRoundedIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={<FormattedMessage id="user.update" />}>
                        <IconButton
                          size="small"
                          disabled={appState.auth.userInfo?.role.some(element => {
                                  return element !== ROLES_NAME.admin
                              }
                          )}
                          color="primary"
                          onClick={() => setFormData(record)}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  );
                },
              },
            ]}
          />
          <Pagination
            size="large"
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingX: 2,
              paddingY: 2,
            }}
            page={params.page + 1}
            count={Math.ceil((data?.pagination.totalElements || 0) / 10)}
            shape="rounded"
            onChange={onPageChange}
          />
        </Paper>
      </Box>
      <UserDialog
        open={!!formData}
        onClose={() => {
          setFormData(undefined);
        }}
        onSubmit={onUpdateUser}
        formData={formData}
        roles={roleData}
      />
      <ChangePasswordDialog
        open={!!formDataChangePassword}
        onClose={() => {
          setFormDataChangePassword(undefined);
        }}
        onSubmit={onChangePassword}
        formData={formDataChangePassword}
      />
    </Box>
  );
};

export default InternalUserPage;
