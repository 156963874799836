import DomainAddIcon from "@mui/icons-material/DomainAdd";
import { Box, Button, Pagination, Portal } from "@mui/material";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import useSWR from "swr";
import LoadingIcon from "../../common/components/LoadingIcon";
import { some } from "../../common/constants";
import useGeneralHook from "../../common/hook/useGeneralHook";
import usePaginationHook from "../../common/hook/usePaginationHook";
import ClinicDialog from "./ClinicDialog";
import CompanyCard from "./CompanyCard";
import CompanyDialog from "./CompanyDialog";
import ExtendTrialDialog from "./ExtendTrialDialog";
interface Props {}

const CustomersPage: React.FunctionComponent<Props> = (props: Props) => {
  const {
    dispatch,
    API_PATHS,
    fetchThunk,
    enqueueSnackbar,
    confirmDialog,
    intl,
  } = useGeneralHook();
  const [clinicFormData, setClinicFormData] = React.useState<some | undefined>(
    undefined
  );
  const [companyFormData, setCompanyFormData] = React.useState<
    some | undefined
  >(undefined);
  const [clinicStatusFormData, setClinicStatusFormData] = React.useState<
    some | undefined
  >(undefined);
  const [extendFormData, setExtendFormData] = React.useState<some | undefined>(
    undefined
  );
  const hookPagination = usePaginationHook();
  const { params, onPageChange } = hookPagination;

  const { data, isValidating, mutate } = useSWR(
    API_PATHS.organization.list({ ...params, orderBy: "DESC" }),
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      json.totalActiveDay = json.remainDays + '/' + json.totalActiveDays;
      return json;
    }
  );

  const onUpdateCompany = React.useCallback(
    async (value: some, setLoading: (loading: boolean) => void) => {
      setLoading(true);
      try {
        const json = value?.id
          ? await dispatch(
              fetchThunk(API_PATHS.organization.update(value?.id), "put", value)
            )
          : await dispatch(fetchThunk(API_PATHS.organization.index, "post", value));
        if (json) {
          setCompanyFormData(undefined);
          mutate();
          enqueueSnackbar(
            intl.formatMessage({
              id: value?.id ? "updateSuccess" : "createSuccess",
            }),
            {
              variant: "success",
            }
          );
        }
      } catch (_) {
        enqueueSnackbar(
          intl.formatMessage({ id: value?.id ? "updateFail" : "createFail" }),
          {
            variant: "error",
          }
        );
      } finally {
        setLoading(false);
      }
    },
    [dispatch, fetchThunk, API_PATHS.organization, mutate, enqueueSnackbar, intl]
  );

  const onUpdateClinic = React.useCallback(
    async (value: some, setLoading: (loading: boolean) => void) => {
      setLoading(true);
      try {
        const json = value?.id
          ? await dispatch(
              fetchThunk(API_PATHS.groups.update(value?.id), "put", value)
            )
          : await dispatch(fetchThunk(API_PATHS.groups.index, "post", value));
        if (json) {
          setClinicFormData(undefined);
          mutate();
          enqueueSnackbar(
            intl.formatMessage({
              id: value?.id ? "updateSuccess" : "clinic.createSuccess",
            }),
            {
              variant: "success",
            }
          );
        }
      } catch (_) {
        enqueueSnackbar(
          intl.formatMessage({ id: value ? "updateFail" : "createFail" }),
          {
            variant: "error",
          }
        );
      } finally {
        setLoading(false);
      }
    },
    [dispatch, fetchThunk, API_PATHS.groups, mutate, enqueueSnackbar, intl]
  );

  const onChangeStatusClinic = React.useCallback(
    async (value: some) => {
      try {
        await dispatch(
          fetchThunk(
            API_PATHS.groups.status(value.id, value.status),
            "post",
            {
              dateToEndTrial: value.expireDate,
            }
          )
        );
        setClinicStatusFormData(undefined);
        mutate();
        enqueueSnackbar(intl.formatMessage({ id: "updateSuccess" }), {
          variant: "success",
        });
      } catch (e) {
        enqueueSnackbar(intl.formatMessage({ id: "updateFail" }), {
          variant: "error",
        });
      }
    },
    [API_PATHS.groups, dispatch, enqueueSnackbar, fetchThunk, intl, mutate]
  );

  const onExtendOfTrialClinic = React.useCallback(
    async (value: some) => {
      try {
        await dispatch(
          fetchThunk(API_PATHS.groups.extend(value.id), "post", {
            expireDate: value.expireDate,
          })
        );
        setExtendFormData(undefined);
        mutate();
        enqueueSnackbar(intl.formatMessage({ id: "updateSuccess" }), {
          variant: "success",
        });
      } catch (e) {
        enqueueSnackbar(intl.formatMessage({ id: "updateFail" }), {
          variant: "error",
        });
      }
    },
    [API_PATHS.groups, dispatch, enqueueSnackbar, fetchThunk, intl, mutate]
  );

  const onDeleteClinic = React.useCallback(
    async (value: some) => {
      const confirm = await confirmDialog.promptConfirmation({
        title: intl.formatMessage({ id: "confirmDeleteTitle" }),
        content: intl.formatMessage(
          { id: "confirmDelete" },
          { name: value.partitionName }
        ),
        warning: true,
      });
      if (confirm) {
        try {
          await dispatch(
            fetchThunk(API_PATHS.organization.update(value.id), "delete")
          );
          mutate();
          enqueueSnackbar(intl.formatMessage({ id: "deleteSuccess" }), {
            variant: "success",
          });
        } catch (e) {
          enqueueSnackbar(intl.formatMessage({ id: "deleteFail" }), {
            variant: "error",
          });
        }
      }
      confirmDialog.close();
    },
    [
      API_PATHS.organization,
      confirmDialog,
      dispatch,
      enqueueSnackbar,
      fetchThunk,
      intl,
      mutate,
    ]
  );

  return (
    <Box flex={1} overflow="hidden">
      <Portal container={document.getElementById("headerContent")}>
        <Button
          variant="contained"
          color="info"
          startIcon={<DomainAddIcon />}
          size="large"
          onClick={() => {
            setCompanyFormData({});
          }}
        >
          <FormattedMessage id="company.add" />
        </Button>
      </Portal>
      <Box height="100%" display="flex" flexDirection="column">
        <Box
          display="flex"
          flexDirection="column"
          gap={1.5}
          flex={1}
          overflow="auto"
          padding={2}
        >
          {isValidating && !data ? (
            <LoadingIcon />
          ) : (
            data?.content?.map((item) => {
              return (
                <CompanyCard
                  info={item}
                  key={item.id}
                  setClinicFormData={setClinicFormData}
                  setCompanyFormData={setCompanyFormData}
                  onDeleteClinic={onDeleteClinic}
                  onChangeStatusClinic={(value) => {
                    if (value.status === "INACTIVE") {
                      onChangeStatusClinic(value);
                    } else {
                      setClinicStatusFormData(value);
                    }
                  }}
                  extendTrialClinic={setExtendFormData}
                />
              );
            })
          )}
        </Box>
        <Pagination
          size="large"
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            paddingX: 2,
            paddingY: 2,
          }}
          page={params.page + 1}
          count={Math.ceil((data?.pagination.totalElements || 0) / 10)}
          shape="rounded"
          onChange={onPageChange}
        />
      </Box>

      <ClinicDialog
        open={!!clinicFormData}
        onClose={() => {
          setClinicFormData(undefined);
        }}
        onSubmit={onUpdateClinic}
        formData={clinicFormData}
      />
      <CompanyDialog
        open={!!companyFormData}
        onClose={() => {
          setCompanyFormData(undefined);
        }}
        onSubmit={onUpdateCompany}
        formData={companyFormData}
      />
      <ExtendTrialDialog
        open={!!extendFormData}
        onClose={() => {
          setExtendFormData(undefined);
        }}
        onSubmit={onExtendOfTrialClinic}
        formData={extendFormData}
      />

      <ExtendTrialDialog
        open={!!clinicStatusFormData}
        onClose={() => {
          setClinicStatusFormData(undefined);
        }}
        onSubmit={onChangeStatusClinic}
        formData={clinicStatusFormData}
      />
    </Box>
  );
};

export default CustomersPage;
