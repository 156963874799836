import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, ButtonBase, Collapse, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import MyLink from "../../common/components/MyLink";
import { IRouteObject } from "../../config/utils";

interface Props {
  data: IRouteObject;
  pathname: string;
  open?: boolean;
}

const DefaultAsideItems: React.FC<Props> = (props: Props) => {
  const { data, pathname, open } = props;
  const [openList, setOpen] = React.useState(false);

  const checkIsActive = data.children
    ? data.children.findIndex((v) => v.path && pathname.includes(v.path)) !== -1
    : data.path
    ? pathname.includes(data.path)
    : false;

  useEffect(() => {
    setOpen(checkIsActive);
  }, [checkIsActive, pathname]);

  if (data.hidden || !data.path) {
    return null;
  }

  if (data.children && data.children.length > 1) {
    return (
      <Box
        bgcolor={checkIsActive ? "secondary.main" : undefined}
        style={{ flex: 1 }}
      >
        <ButtonBase
          sx={{
            borderRadius: 0,
            color: checkIsActive ? "primary.main" : "text.secondary",
            "&:hover": {
              bgcolor: checkIsActive ? "secondary.dark" : "secondary.main",
            },
            minWidth: "-webkit-fill-available",
            paddingX: 3,
            paddingY: 2,
            justifyContent: "flex-start",
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
          onClick={() => setOpen(!openList)}
        >
          <Box
            color={checkIsActive ? "primary.main" : "text.secondary"}
            height={28}
            width={28}
            marginRight={2}
            display="flex"
            justifyContent={"center"}
            alignItems="center"
          >
            {data.icon}
          </Box>
          <Typography
            variant={checkIsActive ? "subtitle1" : "body1"}
            color="inherit"
            noWrap
            style={{ flex: 1, textAlign: "start" }}
          >
            {data.title && <FormattedMessage id={data.title} />}
          </Typography>
          {open && (
            <KeyboardArrowDownIcon
              style={{
                transform: openList ? "rotate(180deg)" : "rotate(0deg)",
                transition: "all 200ms",
                fontSize: 24,
              }}
            />
          )}
        </ButtonBase>
        <Collapse in={openList && open} unmountOnExit>
          {data.children?.map((item) => {
            if (!item.path || item.hidden) {
              return null;
            }
            const checkIsActiveSub = item.path && pathname.includes(item.path);

            return (
              <MyLink
                underline="none"
                to={{
                  pathname: item.path,
                }}
                style={{ display: "flex", flex: 1 }}
              >
                <ButtonBase
                  sx={{
                    color: checkIsActiveSub
                      ? "primary.main"
                      : !checkIsActive
                      ? "text.secondary"
                      : "primary.light",
                    "&:hover": {
                      bgcolor: checkIsActive
                        ? "secondary.dark"
                        : "secondary.main",
                    },
                    paddingX: 3,
                    paddingY: 2,
                    display: "flex",
                    justifyContent: "flex-start",
                    minWidth: "-webkit-fill-available",
                  }}
                >
                  <Box
                    height={28}
                    width={28}
                    marginRight={2}
                    display="flex"
                    justifyContent={"center"}
                    alignItems="center"
                  >
                    {item.icon || (
                      <FiberManualRecordIcon
                        sx={{
                          color: checkIsActiveSub
                            ? "primary.main"
                            : "info.light",
                          transform: checkIsActiveSub
                            ? "scale(.6)"
                            : "scale(.3)",
                          fontSize: 24,
                          transition:
                            "all 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        }}
                      />
                    )}
                  </Box>
                  <Typography
                    noWrap
                    variant={checkIsActiveSub ? "subtitle1" : "body1"}
                    color="inherit"
                  >
                    {item.title && <FormattedMessage id={item.title} />}
                  </Typography>
                </ButtonBase>
              </MyLink>
            );
          })}
        </Collapse>
      </Box>
    );
  }
  return (
    <>
      <MyLink
        underline="none"
        to={{
          pathname: data.path,
        }}
        style={{ display: "flex", flex: 1 }}
      >
        <ButtonBase
          sx={{
            borderRadius: 0,
            bgcolor: checkIsActive ? "secondary.main" : undefined,
            color: checkIsActive ? "primary.main" : "text.secondary",
            "&:hover": {
              bgcolor: checkIsActive ? "secondary.dark" : "secondary.main",
            },
            minWidth: "-webkit-fill-available",
            paddingX: 3,
            paddingY: 2,
            justifyContent: "flex-start",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            height={28}
            width={28}
            marginRight={2}
            display="flex"
            justifyContent={"center"}
            alignItems="center"
            color={checkIsActive ? "primary.dark" : "text.secondary"}
          >
            {data.icon ? (
              data.icon
            ) : (
              <FiberManualRecordIcon
                sx={{
                  color: checkIsActive ? "common.white" : "info.light",
                  transform: checkIsActive ? "scale(.6)" : "scale(.3)",
                  fontSize: 24,
                  transition: "all 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                }}
              />
            )}
          </Box>
          <Typography
            noWrap
            variant={checkIsActive ? "subtitle1" : "body1"}
            color="inherit"
          >
            {data.title && <FormattedMessage id={data.title} />}
          </Typography>
        </ButtonBase>
      </MyLink>
    </>
  );
};

export default DefaultAsideItems;
