import { Edit } from "@mui/icons-material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { IS_PRODUCTION_MODE } from "../../common/API_App";
import TableCustom from "../../common/components/TableCustom";
import { DATE_FORMAT, some } from "../../common/constants";
import ActiveTimeDialog from "./ActiveTimeDialog";

interface Props {
  info: some;
  setClinicFormData: (value: some) => void;
  setCompanyFormData: (value: some) => void;
  onChangeStatusClinic: (value: some) => void;
  extendTrialClinic: (value: some) => void;
  onDeleteClinic: (value: some) => void;
}

const CompanyCard: React.FunctionComponent<Props> = (props: Props) => {
  const {
    info,
    setCompanyFormData,
    setClinicFormData,
    onChangeStatusClinic,
    extendTrialClinic,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = React.useState<some | undefined>(undefined);
  return (
    <Paper>
      <Box
        display="flex"
        gap={1}
        alignItems="center"
        sx={{ paddingY: 1, paddingX: 2 }}
      >
        <Typography
          variant="body1"
          color="primary"
          textTransform="uppercase"
          width={192}
        >
          <FormattedMessage id="id" />
          &nbsp;-&nbsp;{" "}
          <Typography variant="subtitle1" color="primary" component={"span"}>
            {info.code}
          </Typography>
        </Typography>
        <Typography variant="subtitle1" color="primary" flex={1}>
          {info.name}
        </Typography>
        <Tooltip title={<FormattedMessage id="clinic.add" />}>
          <IconButton
            size="small"
            color="primary"
            onClick={() => setClinicFormData({ groupData: info })}
          >
            <AddCircleRoundedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={<FormattedMessage id="company.update" />}>
          <IconButton
            size="small"
            color="primary"
            onClick={() => setCompanyFormData(info)}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        {/* <IconButton
          size="small"
          color="primary"
          disabled={
            info.clinics?.length > 0 &&
            info.clinics.findIndex((val) => val.clinicStatus === "active") !==
              -1
          }
          onClick={() => onDeleteClinic(info)}
        >
          <Delete />
        </IconButton> */}
        <Tooltip title={<FormattedMessage id="collapse" />}>
          <IconButton
            size="small"
            color="primary"
            onClick={() => setOpen(!open)}
          >
            <KeyboardArrowDownRoundedIcon
              style={{
                transition: "all 0.3s",
                transform: open ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Collapse in={open}>
        <Box
          sx={{ paddingX: 1, paddingBottom: 2, marginTop: -1, paddingRight: 4 }}
        >
          <TableCustom
            containerProps={{ style: { minHeight: "unset" } }}
            hideColumnIndex
            dataSource={info.groups}
            columns={[
              { title: "clinic.name", dataIndex: "name", width: 200 },
              {
                title: "clinic.clinicType",
                dataIndex: "version"
              },
              { title: "clinic.specialization",dataIndex: "specializationType" },
              { title: "email", dataIndex: "emailContact" },
              { title: "phone", dataIndex: "phoneContact" },
              {
                title: "clinic.totalActiveTime",
                dataIndex: "totalActiveTime",
              },
              {
                title: "clinic.totalActiveDay",
                dataIndex: "totalActiveDay",
                  render: (record) => {
                      return (
                          <Typography variant="inherit">
                              {record.remainDays + '/' + record.totalActiveDays}
                          </Typography>
                      );
                  },
              },
              {
                title: IS_PRODUCTION_MODE
                  ? "clinic.endDateOfLicense"
                  : "clinic.dateToEndTrial",
                dataIndex: "expireDate",
                render: (record) => {
                  return (
                    <Typography
                      variant="inherit"
                      color={
                          (record.status === "INACTIVE" || moment(record.expireDate).diff(
                            moment(),
                            "day") < 0)
                          ? "error"
                          : moment(record.expireDate).diff(
                              moment(),
                              "day"
                            ) < 3
                          ? "warning.main"
                          : "textPrimary"
                      }
                    >
                      {record.expireDate &&
                        moment(record.expireDate).format(DATE_FORMAT)}
                    </Typography>
                  );
                },
              },
              {
                title: "",
                align: "right",
                render: (record) => {
                  return (
                    <Box display="flex" gap={1} justifyContent="flex-end">
                      <Tooltip title={<FormattedMessage id="chart" />}>
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() =>
                            setFormData({
                              groupData: info,
                              clinicData: record,
                            })
                          }
                        >
                          <SignalCellularAltIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title={
                          <FormattedMessage
                            id={
                              IS_PRODUCTION_MODE
                                ? "clinic.extendLicense"
                                : "clinic.extendTrial"
                            }
                          />
                        }
                      >
                        <div>
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={() => extendTrialClinic(record)}
                            disabled={record.status === "INACTIVE"}
                          >
                            <WatchLaterIcon />
                          </IconButton>
                        </div>
                      </Tooltip>
                      <Tooltip title={<FormattedMessage id="clinic.update" />}>
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() =>
                            setClinicFormData({
                              groupData: info,
                              clinicData: record,
                            })
                          }
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={<FormattedMessage id="status" />}>
                        <Switch
                          checked={record.status === "ACTIVE"}
                          onChange={(e) =>
                            onChangeStatusClinic({
                              ...record,
                              status: e.target.checked
                                ? "ACTIVE"
                                : "INACTIVE",
                            })
                          }
                        />
                      </Tooltip>
                    </Box>
                  );
                },
              },
            ]}
          />
        </Box>
      </Collapse>
      <ActiveTimeDialog
        open={!!formData}
        onClose={() => {
          setFormData(undefined);
        }}
        formData={formData}
      />
    </Paper>
  );
};

export default CompanyCard;
