import { stringify } from "query-string";
import { some } from "./constants";

export const IS_DEV_EVN: boolean =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export const IS_PRODUCTION_MODE =
  window.location.hostname.startsWith("madi.") ||
  window.location.hostname.startsWith("id.madi.");

export const APIHost = IS_DEV_EVN
  ? "/api/identity"
  : window.location.hostname.startsWith("test.")
  ? "https://dev-api.medi.technology/identity/api"
  : window.location.hostname.startsWith("staging.")
  ? "https://stg-api.medi.technology/identity/api"
  : window.location.hostname.startsWith("demo.")
  ? "https://demo-api.medi.technology/identity/api"
  : window.location.hostname.startsWith("madi.")
  ? "https://prod-api.medi.technology/identity/api"
  : window.location.hostname.startsWith("test-id.")
  ? "https://id-dev-api.medi.technology/identity/api"
  : window.location.hostname.startsWith("staging-id.")
  ? "https://id-stg-api.medi.technology/identity/api"
  : window.location.hostname.startsWith("demo-id.")
  ? "https://id-demo-api.medi.technology/identity/api"
  : window.location.hostname.startsWith("id.madi.")
  ? "https://id-prod-api.medi.technology/identity/api"
  : "https://prod-api.medi.technology/identity/api";

export const API_PATHS = {
  auth: {
    login: `${APIHost}/v1.0/auth/sign-in`,
    logout: `${APIHost}/v1.0/auth/sign-out`,
  },
  organization: {
    list: (params: some) => `${APIHost}/v1.0/organizations?${stringify(params)}`,
    index: `${APIHost}/v1.0/organizations`,
    update: (id) => `${APIHost}/v1.0/organizations/${id}`,
  },
  groups: {
    list: (params: some) => `${APIHost}/v1.0/groups?${stringify(params)}`,
    index: `${APIHost}/v1.0/madi/groups`,
    update: (id) => `${APIHost}/v1.0/madi/groups/${id}`,
    status: (id: string, status: string) => `${APIHost}/v1.0/madi/groups/${id}/${status}`,
    extend: (id: string) => `${APIHost}/v1.0/madi/groups/extend/${id}`,
  },
  active: {
    index: (id) => `${APIHost}/active/${id}`,
  },
  users: {
    list: (params: some) => `${APIHost}/v1.0/users?${stringify(params)}`,
    index: `${APIHost}/v1.0/users/internal`,
    update: (id) => `${APIHost}/v1.0/users/internal/${id}`,
    changePassword: (id) => `${APIHost}/v1.0/users/${id}/password`,
  },
  roles: {
    list: () => `${APIHost}/v1.0/roles`,
  }
};
