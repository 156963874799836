export const ROLES = {
  admin: "admin",
  sale: "sale",
  accounting: "accounting",
  inventory: "inventory",
  provider: "provider",
};

export const ROLES_NAME = {
  admin: "MEDRING_ADMIN"
};
